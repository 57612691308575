import React from "react";

type Props = {};

function About({}: Props) {
  return (
    <>
      <section className="contact section container" id="contact">
        <div className="about__container grid">
          <div className="about__data">
            <h1 className="services__subtitle">Experiences</h1>

            <h3>Sr. Software Engineer Full creative Pvt Ltd.</h3>
            <p className="services__description">
              2022- Full creative Pvt Ltd.
            </p>
            <h3>Software Engineer</h3>
            <p className="services__description">
              2019- Full creative Pvt Ltd.
            </p>
            <h3>Junior Software Engineer</h3>
            <p className="services__description">
              2016- Full creative Pvt Ltd.
            </p>
          </div>

          <div className="about__data">
            <h1 className="services__subtitle">Educations</h1>

            <h3>Master of Computer Applications</h3>
            <p className="services__description">
              2015- AMS Eng College, Namakkal
            </p>
            <h3>Bsc. Computer Science</h3>
            <p className="services__description">
              2013- EMS Memorial College of Applied Science, Kunnoth
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
