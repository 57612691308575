import React, { useEffect, useState } from "react";
import Header from "./components/header/Header";
import Container from "./components/container/Container";
import "./assets/css/styles.css";
import { getCurrentSystemTheme } from "./helper/theme-helper";
import { type } from "os";

type Props = {
  systemTheme: string;
};
function App({ systemTheme }: Props) {
  const [page, setPage] = useState(0);
  const [theme, setTheme] = useState(systemTheme);
  const updatePage = (newPage: number) => {
    setPage(newPage);
  };
  const updateTheme = () => {
    let newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    if (newTheme === "dark") document.body.classList.add(newTheme + "-theme");
    else document.body.classList.remove("dark-theme");
  };
  const changeThemeOnload = () => {
    if (theme === "dark") document.body.classList.add(theme + "-theme");
    else document.body.classList.remove("dark-theme");
  };
  useEffect(() => {
    changeThemeOnload();
  }, []);

  return (
    <div>
      <Header
        callback={updatePage}
        currentPage={page}
        theme={theme}
        updateTheme={updateTheme}
      />
      <Container page={page} />
    </div>
  );
}

export default App;
