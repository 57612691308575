import React from "react";
import ProfPic from "../../assets/img/profile.png";
import {
  BiLogoGithub,
  BiLogoLinkedinSquare,
  BiLogoTwitter,
} from "react-icons/bi";

type Props = {};

function Home({}: Props) {
  return (
    <section className="home section" id="home">
      <div className="home__container container grid">
        <img src={ProfPic} className="svg__img svg__color home__img" />

        <div className="home__data">
          <h1 className="home__title">Midhun Balan</h1>
          <h5>Software Engineer</h5>
          <br />
          <p className="home__description">
            Welcome to my portfolio! I'm a software engineer with 7 years of
            experience in scheduling, micro-front-end development, building
            components using module federation, and backend solutions.
          </p>
          <p>
            I've worked in different areas like Communication, Application
            Security, Scheduling, and Platform solutions. I'm skilled in Java,
            JavaScript, and Node.js. I prioritize best practices and coding
            standards to deliver top-notch software solutions.
          </p>
          <br />
          <p>
            My expertise lies in working efficiently in agile settings,
            collaborating with diverse teams, and ensuring on-time project
            delivery.
          </p>

          <a
            href="https://github.com/MidhunBalan"
            target="_blank"
            rel="noreferrer"
            className="social__home-icon"
          >
            <BiLogoGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/midhun-balan-1a649914b"
            target="_blank"
            rel="noreferrer"
            className="social__home-icon"
          >
            <BiLogoLinkedinSquare />
          </a>
          <a
            href="https://twitter.com/midhunbalan_dev"
            target="_blank"
            rel="noreferrer"
            className="social__home-icon"
          >
            <BiLogoTwitter />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Home;
