import React from "react";
import { BiSolidSun, BiMoon, BiMenu } from "react-icons/bi";

type Props = {
  callback: (page: number) => void;
  theme: string;
  currentPage: number;
  updateTheme: () => void;
};

const Header = ({ callback, currentPage, updateTheme, theme }: Props) => {
  const openMobileNavbar = () => {
    const navMenu = document.getElementById("nav-menu");
    navMenu?.classList.toggle("show-menu");
  };
  const updatePage = (newPage: number) => {
    callback(newPage);
    openMobileNavbar();
  };
  return (
    <header className="header" id="header">
      <nav className="nav container">
        <div className="nav__logo" onClick={() => updatePage(0)}>
          Midhun Balan
        </div>

        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list">
            <li className="nav__item" onClick={() => updatePage(0)}>
              <div
                className={`nav__link button_link ${
                  currentPage === 0 ? "active-link" : ""
                }`}
              >
                Home
              </div>
            </li>
            <li className="nav__item" onClick={() => updatePage(1)}>
              <div
                className={`nav__link button_link ${
                  currentPage === 1 ? "active-link" : ""
                }`}
              >
                About
              </div>
            </li>
            <li className="nav__item" onClick={() => updatePage(2)}>
              <div
                className={`nav__link button_link ${
                  currentPage === 2 ? "active-link" : ""
                }`}
              >
                Blogs
              </div>
            </li>
            <li className="nav__item" onClick={() => updatePage(3)}>
              <div
                className={`nav__link button_link ${
                  currentPage === 3 ? "active-link" : ""
                }`}
              >
                Contact
              </div>
            </li>

            <i
              className="change-theme"
              id="theme-button"
              onClick={() => updateTheme()}
            >
              {theme === "dark" ? <BiSolidSun /> : <BiMoon />}
            </i>
          </ul>
        </div>

        <div
          className="nav__toggle"
          id="nav-toggle"
          onClick={() => openMobileNavbar()}
        >
          <BiMenu />
        </div>
      </nav>
    </header>
  );
};

export default Header;
