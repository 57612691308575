import React from "react";
import {
  BiLogoGithub,
  BiLogoLinkedinSquare,
  BiLogoTwitter,
} from "react-icons/bi";

type Props = {};

function Contact({}: Props) {
  return (
    <section className="app section container">
      <div className="app__container grid extra__padding">
        <div className="app__data">
          <h2 className="section__title-center">Contact Me</h2>
          <p className="app__description">
            If you have any queries or would like to discuss potential
            collaborations, please feel free to reach out to me via email at
            midhunbalan.dev@gmail.com
          </p>
          <p>Thank you for taking the time to review my portfolio.</p>
          <br />
          <p>
            I look forward to the opportunity to contribute to your
            organization.
          </p>
          <a
            href="https://github.com/MidhunBalan"
            target="_blank"
            rel="noreferrer"
            className="social__home-icon"
          >
            <BiLogoGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/midhun-balan-1a649914b"
            target="_blank"
            rel="noreferrer"
            className="social__home-icon"
          >
            <BiLogoLinkedinSquare />
          </a>
          <a
            href="https://twitter.com/midhunbalan_dev"
            target="_blank"
            rel="noreferrer"
            className="social__home-icon"
          >
            <BiLogoTwitter />
          </a>
        </div>
      </div>
    </section>
  );
}

export default Contact;
