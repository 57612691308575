import React from 'react';
import Anchor from '../atoms/Anchor';

type Props = {};

function Blog({}: Props) {
  return (
    <section className='services section container' id='services'>
      <h2 className='section__title'>Blogs/Article which I have written</h2>
      <div className='services__container grid'>
        <div className='services__data'>
          <h3 className='services__subtitle'>
            Google Cloud Run: What, Why and How
          </h3>

          <p className='services__description'>
            Google Cloud provides many features, and one of them is Google Cloud
            Run. This article is tailored specifically for beginners seeking to
            ...
          </p>
          <div>
            <Anchor
              href='https://medium.com/@midhunbalan.dev/google-cloud-run-what-why-and-how-806e048df811'
              className='button button-link'
              target='_blank'
              rel='noreferrer'
              title='Learn More'
            />
          </div>
        </div>

        {/* <div className='services__data'>
          <h3 className='services__subtitle'>
            Guide to JavaScript arrays and essential methods.
          </h3>

          <p className='services__description'>
            JavaScript arrays are fundamental data structures used to store
            collections of elements. They come with a range of methods that
            enable manipulation, traversal, and transformation of the array
            elements...
          </p>
          <div>
            <Anchor
              href='#'
              className='button button-link'
              target='_blank'
              rel='noreferrer'
              title='Learn More'
            />
          </div>
        </div> */}
{/* 
        <div className='services__data'>
          <h3 className='services__subtitle'>
            Guide to JavaScript Modules and Use Cases.
          </h3>

          <p className='services__description'>
            JavaScript modules are a way to organize code into separate files,
            making it easier to manage, reuse, and maintain. They allow you to
            break down your code into smaller, more manageable pieces,
            encapsulating functionality and data within distinct modules...
          </p>
          <div>
            <Anchor
              href='#'
              className='button button-link'
              target='_blank'
              rel='noreferrer'
              title='Learn More'
            />
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default Blog;
