import React from 'react';

type AnchorProps = {
  href: string;
  className: string;
  target: string;
  rel: string;
  title: string;
};

function Anchor({ href, className, target, rel, title }: Readonly<AnchorProps>) {
  return (
    <a href={href} className={className} target={target} rel={rel}>
      {title}
    </a>
  );
}

export default Anchor;
