import React, { useState } from "react";
import Home from "../sections/Home";
import About from "../sections/About";
import Blog from "../sections/Blog";
import Contact from "../sections/Contact";

type Props = {
  page: number;
};

function Container({ page }: Props) {
  return (
    <div>
      {page === 0 && <Home />}
      {page === 1 && <About />}
      {page === 2 && <Blog />}
      {page === 3 && <Contact />}
    </div>
  );
}

export default Container;
